<template>
  <div class="nav-wrapper">
    <b-navbar toggleable="lg" type="light"
      ><b-navbar-brand href="/doctor/consultation/await">
        <img
          :src="require(`@/assets/go-doc-logo-words.png`)"
          class="navbar__logo"
      /></b-navbar-brand>
      <b-navbar-nav>
        <b-nav-item href="/doctor/consultation/await"
          ><span class="router-link">Pending</span></b-nav-item
        >
        <b-nav-item href="/doctor/consultation/history"
          ><span class="router-link">History</span></b-nav-item
        >
      </b-navbar-nav>
      <b-navbar-nav class="ml-auto">
        <b-nav-item right>
          <b-button type="button" @click="logOut()"
            >Log Out</b-button
          ></b-nav-item
        >
      </b-navbar-nav>
    </b-navbar>
  </div>
</template>

<script>
export default {
  methods: {
    logOut() {
      this.clearStore();
    },
    async clearStore() {
      await this.$store.dispatch("logout");
      this.$router.push("/doctor/login");
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-wrapper {
  padding: 24px;
}
.nav-item {
  padding: 0px 15px;
}
.router-link {
  font-size: 24px;
  font-weight: 400;
  color: #000000;
  &.router-link--small {
    font-size: 16px;
  }
  &.router-link-active {
    font-weight: 700;
  }
  &:hover {
    text-decoration: none;
  }
}
.dot--small {
  height: 50px;
  width: 50px;
}
.navbar__logo {
  width: 150px;
}
</style>
