import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    snackBarMsg: "",
    snackBarVariant: "",
    token: null,
    userType: null,
    isLoading: false,
    userName: null,
    userEmail: null,
  },
  mutations: {
    SET_SNACKBAR_MSG(state, payload) {
      state.snackBarMsg = payload;
    },
    SET_SNACKBAR_VARIANT(state, payload) {
      state.snackBarVariant = payload;
    },
    SET_TOKEN: (state, payload) => {
      localStorage.setItem("goDoc-token", payload);
      state.token = payload;
    },
    SET_USER_TYPE: (state, payload) => {
      localStorage.setItem("goDoc-userType", payload);
      state.userType = payload;
    },
    SHOW_SPINNER(state) {
      state.isLoading = true;
    },
    HIDE_SPINNER(state) {
      state.isLoading = false;
    },
    SET_USER_NAME: (state, payload) => {
      state.userName = payload;
    },
    SET_EMAIL: (state, payload) => {
      state.userEmail = payload;
    },
  },
  actions: {
    init({ commit }) {
      const localToken = localStorage.getItem("goDoc-token");
      if (localToken) {
        commit("SET_TOKEN", localToken);
      }
      const localUserType = localStorage.getItem("goDoc-userType");
      if (localUserType) {
        commit("SET_USER_TYPE", localUserType);
      }
    },
    login: ({ commit }, payload) => {
      commit("SET_TOKEN", payload.token);
      commit("SET_USER_TYPE", payload.userType);
    },
    logout: ({ state }) => {
      return new Promise((resolve) => {
        localStorage.removeItem("goDoc-token");
        localStorage.removeItem("goDoc-userType");
        state.token = null;
        state.userType = null;
        resolve();
      });
    },
    activateSnackBar(context, payload) {
      context.commit("SET_SNACKBAR_MSG", payload.msg);
      context.commit("SET_SNACKBAR_VARIANT", payload.type);
    },
    setUserName(context, payload) {
      context.commit("SET_USER_NAME", payload);
    },
    setUserEmail(context, payload) {
      context.commit("SET_EMAIL", payload);
    },
    showSpinner({ commit }) {
      commit("SHOW_SPINNER");
    },
    hideSpinner({ commit }) {
      commit("HIDE_SPINNER");
    },
  },
  getters: {
    isAuthenticated: (state) => {
      return state.token;
    },
    isDoctor: (state) => {
      return state.userType == "doctor";
    },
    isLoading: (state) => {
      return state.isLoading;
    },
    getUserName: (state) => {
      return state.userName;
    },
    getUserEmail: (state) => {
      return state.userEmail;
    },
  },
  modules: {},
});
