const config = {
  apiKey: "AIzaSyAWt7yCAKhI5sb6JWsghpNFFeQ1A0XXD6g",
  authDomain: "godoc-rd.firebaseapp.com",
  databaseURL:
    "https://godoc-rd-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "godoc-rd",
  storageBucket: "godoc-rd.appspot.com",
  messagingSenderId: "904422846981",
  appId: "1:904422846981:web:4d1d14abfdf9bc73fba26c",
  measurementId: "G-HBG5Y4EMQS",
};
export default config
