<template>
  <div>
    <section
      class="content register-log-in-section d-flex flex-column align-items-center justify-content-around"
    >
      <div class="content d-flex flex-column align-items-center">
        <img
          :src="require(`@/assets/go-doc-logo.png`)"
          class="log-in-section__logo"
        />
        <p class="mt-1 company-title text--boldest">
          <span class="text-primary">Rapi</span>Dr
        </p>
        <p class="text-muted">Quality Healthcare, One Click Away</p>
      </div>
      <div class="w-100">
        <b-form @submit.prevent="onSubmit">
          <b-form-input
            class="mt-2"
            type="email"
            placeholder="Email Address"
            v-model="email"
            autocomplete="email"
          ></b-form-input
          ><b-form-input
            class="mt-2"
            type="password"
            placeholder="Enter password"
            v-model="password"
            autocomplete="password"
          ></b-form-input>
          <router-link to="/register" class="float-left mt-2 mb-3"
            ><small> Sign up</small></router-link
          >
          <router-link to="/forgetpassword" class="float-right mt-2 mb-3"
            ><small> Forgot password?</small></router-link
          >

          <b-button
            variant="primary"
            type="submit"
            class="btn-block shadow"
            :disabled="!isDataFilled"
          >
            LOGIN
          </b-button>
        </b-form>
      </div>
    </section>
    <div class="d-flex flex-column mb-5">
      <small class="text-muted">MOH Telemedicine License</small>
      <small class="text-muted">R/24M1375/MDS/001/242</small>
      <small class="text-muted">UEN 202306095G</small>
    </div>
  </div>
</template>
<script>
import {
  getAuth,
  signInWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
export default {
  name: "log-in",
  data() {
    return {
      email: null,
      password: null,
    };
  },
  methods: {
    onSubmit() {
      this.$store.dispatch("showSpinner");
      const auth = getAuth();
      signInWithEmailAndPassword(auth, this.email, this.password)
        .then((userCredentials) => {
          this.auth = auth;
          this.checkIsPatient(userCredentials.user.accessToken);
        })
        .catch((error) => {
          this.$store.dispatch("activateSnackBar", {
            msg: this.processAuthErrors(error.message),
            type: "danger",
          });
          this.$store.dispatch("hideSpinner");
        });
    },
    processAuthErrors(message) {
      if (message.includes("wrong-password")) {
        return "Wrong Password, please try again";
      }
      if (message.includes("user-not-found")) {
        return "Invalid Email, please try again";
      }
      if (message.includes("too-many-requests")) {
        return "Too many failed log in attempts. Please reset your password or try again later";
      } else {
        return "Error, please try again later";
      }
    },
    resendEmailVerification() {
      sendEmailVerification(this.auth.currentUser)
        .then(() => {
          this.$store.dispatch("activateSnackBar", {
            msg: "Please check your email to verify your account",
            type: "danger",
          });
        })
        .catch((error) => {
          this.$store.dispatch("activateSnackBar", {
            msg: error.message,
            type: "danger",
          });
        });
    },
    async checkIsPatient(accessToken) {
      const res = await this.$http
        .get("role", {
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .json();
      this.$store.dispatch("hideSpinner");
      if (res.error) {
        if (res.message.includes("Email not verified")) {
          this.resendEmailVerification();
        } else {
          this.$store.dispatch("activateSnackBar", {
            msg: res.message,
            type: "danger",
          });
        }
      } else {
        if (res.role == "patient") {
          this.$store.dispatch("login", {
            token: accessToken,
            userType: "patient",
          });
          this.$router.push("/");
        } else {
          this.$store.dispatch("activateSnackBar", {
            msg: "Sorry you are not permitted to log in to the patients portal",
            type: "danger",
          });
        }
      }
    },
  },
  computed: {
    isDataFilled() {
      return (
        this.email != null &&
        this.email != "" &&
        this.password != null &&
        this.password != ""
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.register-log-in-section {
  padding: 15px;
}
.log-in-option {
  font-size: 11px;
}
.log-in-section__logo {
  width: 50vw;
}
</style>
