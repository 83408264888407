<template>
  <div
    class="splash-screen active d-flex flex-column justify-content-center align-items-center"
    id="splash-screen"
  >
    <img
      :src="require(`@/assets/go-doc-logo.png`)"
      class="splash-screen__logo"
    />
    <div>
      <p class="mt-2 mb-0 company-title text--boldest">
        Rapi<span class="text-white">Dr</span>
      </p>
      <div class="d-flex flex-column text-right splash-screen__sub-text">
        <small class="text-muted">By GoDoc</small>
      </div>
    </div>
    <div
      class="d-flex flex-column splash-screen__sub-text splash-screen__sub-text--bottom"
    >
      <small class="text-muted">MOH Telemedicine License</small>
      <small class="text-muted">R/24M1375/MDS/001/242</small>
      <small class="text-muted">UEN 202306095G</small>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    fadeOutSplashScreen() {
      document.getElementById("splash-screen").classList.remove("active");
    },
  },
  mounted() {
    window.setTimeout(this.fadeOutSplashScreen, 1000);
  },
};
</script>
<style lang="scss" scoped>
.splash-screen {
  background-color: #3f8efc;
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: -200vh;
  z-index: 99999;
  transition: top 500ms linear;
  &.active {
    top: 0;
  }
}
.splash-screen__logo {
  width: 50vw;
  max-width: 300px;
}
.splash-screen__sub-text {
  margin-top: -15px;
  .text-muted {
    color: #bdbdbd !important;
  }
}
.splash-screen__sub-text--bottom {
  position: absolute;
  bottom: 30px;
}
</style>
