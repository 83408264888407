<template>
  <section class="content d-flex flex-column justify-content-center align-items-center maintenance-page">
    <b-container fluid="sm">
      <img :src="threeDoctorsSVG" class="mb-5 three-doctors-svg" />
      <h1 class="maintenance__title">Site is under maintainance</h1>
      <p>
        Thanks for choosing Rapidr! We are undergoing urgent server maintenance right now and we will be back will be
        back soon!
      </p>
      <p>We know it's a little bit of a bummer, but it ensures we provide the best service for you.</p>
      <p>Apologies for the inconvenience caused!
      </p>
    </b-container>
  </section>
</template>

<script>
export default {
  data() {
    return {
      threeDoctorsSVG: require("@/assets/three-doctors.svg"),
    };
  },
  methods: {
    maintenanceContactForm() {
      window.open("https://forms.gle/ioZa5fiT3HHcPUSLA", "_blank");
    },
  },
};
</script>

<style>
.maintainance-page {
  height: 100vh;
  font-size: 2rem;
  font-weight: 600;
}

.maintenance__title {
  margin-bottom: 100px;
}

.three-doctors-svg {
  max-width: 500px;
}

.hyperlink {
  color: blue;
  text-decoration: underline;
}
</style>
